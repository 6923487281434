














































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import ManageDialog from './components/ManageDialog.vue';
import { LevelInformationService } from '@/services/abilityAssessment/level-information-service';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
  components: { ManageDialog }
})
export default class levelInformationManagement extends Vue {
  @Inject(LevelInformationService)
  private levelInformationService!: LevelInformationService;

  public ADD: string = ADD;
  public EDIT: string = EDIT;
  public DETAIL: string = DETAIL;
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  public tableData: any[] = [];
  public failReason: any[] = [];
  public loading: Boolean = false;
  // Dialog相关
  public dialogVisible: boolean = false;
  public dialogType: string = '';
  public currentRow: any = null;

  public async created(): Promise<void> {
    await this.getList();
  }
  // 清空数据
  public clearData() {
    this.dialogType = '';
    this.currentRow = null;
  }
  // 搜索
  public search() {
    this.form.current = 1;
    this.getList();
  }
  // 分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  // 关闭Dialog
  public dialogCancel(isRefresh: boolean) {
    this.clearData();
    this.dialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  // 获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.levelInformationService.getLevelList(this.form);
    if (res) {
      this.tableData = res.records || [];
      this.form.total = res.total;
      this.form.size = res.size;
      this.form.current = res.current;
    }
    this.loading = false;
  }
  // 新增/编辑/查看
  public async handManage(type: string, row?: any) {
    this.dialogType = type;
    this.currentRow = type !== ADD ? deepCopy(row) : null;
    this.dialogVisible = true;
  }
  // 删除
  public async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    };
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      let res = await this.levelInformationService.removeLevel(param);
      if (res) {
        this.getList();
        this.$message({
          message: '删除成功',
          type: 'success'
        });
      }
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }
}
